$color-dark: #353c48;

.dark-sidebar {
  &:not(.sidebar-mini) {
    .sidebar-style-2 .sidebar-menu > li.active {
      ul.dropdown-menu li a {
        background-color: $color-dark;
      }
    }
  }
  &.sidebar-mini .main-sidebar {
    .sidebar-menu {
      background-color: $color-dark;
      li {
        &.active {
          a {
            box-shadow: none;
            color: #fff;
          }
        }
        ul.dropdown-menu li {
          a {
            background-color: $color-dark;
          }
          &:hover a {
            background-color: $color-dark;
          }
        }
      }
    }
    .sidebar-user {
      background-color: $color-dark;
    }
    .dropdown-title {
      background-color: $color-dark;
      color: #fff !important;
    }
    &:after {
      background-color: $color-dark;
    }
  }
  .navbar {
    &.active {
      background-color: #fff;
    }
    .form-inline {
      .form-control {
        background-color: #f2f2f2;
      }
      .btn {
        background-color: #f2f2f2;
      }
    }
  }
  .main-sidebar {
    background-color: $color-dark;
    .sidebar-brand {
      background-color: $color-dark;
      a {
        color: #fff;
      }
    }
    .sidebar-menu li {
      a {
        color: #a9b7d0;
      }
      &.menu-header {
        color: #ebecf1;
      }
      &.active a {
        color: #fff;
        background-color: rgba(31, 32, 46, 0.3);
      }
      ul.menu-dropdown li {
        a {
          color: #868e96;
        }
        &.active a {
          color: #fff;
        }
      }
      a:hover {
        background-color: rgba(0, 0, 0, 0.2);
      }
      ul.dropdown-menu {
        a {
          color: #a9b7d0;
        }
        li {
          &.active > a {
            color: #ffffff;
            &:before {
              color: #ffffff;
            }
          }
          a:hover {
            color: #fff;
            &:before {
              color: #fff;
            }
          }
        }
      }
    }

    .sidebar-user .sidebar-user-details .user-name {
      color: #ededed;
    }
  }
  .btn-primary,
  .btn-primary.disabled {
    box-shadow: none;
  }
}
