.card {
  @include card_base();
  .card-header,
  .card-body,
  .card-footer {
    background-color: transparent;
    padding: 20px 25px;
  }
  .navbar {
    position: static;
  }
  .card-body {
    padding-top: 20px;
    padding-bottom: 20px;
    .section-title {
      margin: 30px 0 10px 0;
      font-size: 16px;
      &:before {
        margin-top: 8px;
      }
    }
    .section-title + .section-lead {
      margin-top: -5px;
    }
    p {
      font-weight: 500;
    }
  }
  .card-header {
    border-bottom-color: #f9f9f9;
    line-height: 30px;
    -ms-grid-row-align: center;
    align-self: center;
    width: 100%;
    padding: 10px 25px;
    display: flex;
    align-items: center;
    .btn {
      margin-top: 1px;
      padding: 2px 15px;
      &:not(.note-btn) {
        border-radius: 30px;
      }
      &:hover {
        box-shadow: none;
      }
    }
    .form-control {
      height: 31px;
      font-size: 13px;
      border-radius: 30px;
      + .input-group-btn .btn {
        margin-top: -1px;
      }
    }
    h4 {
      font-size: 16px;
      line-height: 28px;
      padding-right: 10px;
      margin-bottom: 0;
      + .card-header-action,
      + .card-header-form {
        margin-left: auto;
        .btn {
          font-size: 12px;
          border-radius: 30px !important;
          padding-left: 13px !important;
          padding-right: 13px !important;
          &.active {
            @include button-shadow(primary);
            background-color: color(primary);
            color: #fff;
          }
        }
        .dropdown {
          display: inline;
        }
        .btn-group {
          .btn {
            border-radius: 0 !important;
          }
          .btn:first-child {
            border-radius: 30px 0 0 30px !important;
          }
          .btn:last-child {
            border-radius: 0 30px 30px 0 !important;
          }
        }
        .input-group {
          .form-control {
            border-radius: 30px 0 0 30px !important;
            + .input-group-btn {
              .btn {
                border-radius: 0 30px 30px 0 !important;
              }
            }
          }
          .input-group-btn {
            + .form-control {
              border-radius: 0 30px 30px 0 !important;
            }
            .btn {
              margin-top: -1px;
              border-radius: 30px 0 0 30px !important;
            }
          }
        }
      }
    }
  }
  .card-footer {
    background-color: transparent;
    border: none;
  }
  &.card-mt {
    margin-top: 30px;
  }
  &.card-progress {
    &:after {
      @include overlay();
      z-index: 99;
    }
    .card-progress-dismiss {
      position: absolute;
      top: 66%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      z-index: 999;
      color: #fff !important;
      padding: 5px 13px;
    }
    &.remove-spinner {
      .card-progress-dismiss {
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
      }
    }
    &:not(.remove-spinner) {
      &:after {
        background-image: url("../img/spinner.svg");
        background-size: 80px;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }
  &.card-primary {
    border-top: 2px solid color(primary);
  }
  &.card-secondary {
    border-top: 2px solid color(fontdark);
  }
  &.card-success {
    border-top: 2px solid color(success);
  }
  &.card-danger {
    border-top: 2px solid color(danger);
  }
  &.card-warning {
    border-top: 2px solid color(warning);
  }
  &.card-info {
    border-top: 2px solid color(info);
  }
  &.card-dark {
    border-top: 2px solid color(dark);
  }
  &.card-hero {
    .card-header {
      padding: 40px;
      background-image: linear-gradient(
        to bottom,
        color(primary),
        color_lighten(primary, 10%)
      );
      color: #fff;
      overflow: hidden;
      height: auto;
      min-height: auto;
      display: block;
      h4 {
        font-size: 40px;
        line-height: 1;
      }
      .card-description {
        margin-top: 5px;
        font-size: 16px;
      }
      .card-icon {
        float: right;
        color: color_lighten(primary, 8%);
        margin: -60px;
        .ion,
        .fas,
        .far,
        .fab,
        .fal {
          font-size: 140px;
        }
      }
    }
  }
  &.card-statistic-1 .card-header,
  &.card-statistic-2 .card-header {
    border-color: transparent;
    padding-bottom: 0;
    height: auto;
    min-height: auto;
    display: block;
  }
  &.card-statistic-1 .card-icon {
    width: 30px;
    height: 30px;
    margin: 10px 0px 0px 20px;
    border-radius: 3px;
    line-height: 78px;
    text-align: center;
    float: left;
    font-size: 30px;
  }
  &.card-statistic-1 .card-header h4,
  &.card-statistic-2 .card-header h4 {
    line-height: 1.2;
    color: color(muted);
  }
  &.card-statistic-1 .card-body,
  &.card-statistic-2 .card-body {
    padding-top: 0;
  }
  &.card-statistic-1 .card-body,
  &.card-statistic-2 .card-body {
    font-size: 26px;
    font-weight: 700;
    color: color(fontdark);
    padding-bottom: 0;
  }
  &.card-statistic-1,
  &.card-statistic-2 {
    display: inline-block;
    width: 100%;
  }
  &.card-statistic-1 .card-icon,
  &.card-statistic-2 .card-icon {
    width: 80px;
    height: 80px;
    margin: 10px;
    border-radius: 3px;
    line-height: 94px;
    text-align: center;
    float: left;
    border-radius: 50px;
    margin-right: 15px;
    .ion,
    .fas,
    .far,
    .fab,
    .fal {
      font-size: 22px;
      color: #fff;
    }
  }
  &.card-statistic-1 .card-icon {
    line-height: 90px;
  }
  &.card-statistic-2 .card-icon {
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 22px;
    margin: 25px;
    box-shadow: 5px 3px 10px 0 rgba(21, 15, 15, 0.3);
    border-radius: 10px;
    background: #6777ef;
  }
  &.card-statistic-1 .card-header,
  &.card-statistic-2 .card-header {
    padding-bottom: 0;
    padding-top: 25px;
  }
  &.card-statistic-2 .card-body {
    padding-top: 20px;
  }
  &.card-statistic-2 .card-header + .card-body,
  &.card-statistic-2 .card-body + .card-header {
    padding-top: 0;
  }
  &.card-statistic-1 .card-header h4,
  &.card-statistic-2 .card-header h4 {
    font-weight: 600;
    font-size: 13px;
    letter-spacing: 0.5px;
  }
  &.card-statistic-1 .card-header h4 {
    margin-bottom: 0;
  }
  &.card-statistic-2 .card-header h4 {
    text-transform: none;
    margin-bottom: 0;
  }
  &.card-statistic-1 .card-body {
    font-size: 20px;
  }
  &.card-statistic-2 {
    .card-chart {
      padding-top: 20px;
      margin-left: -9px;
      margin-right: -1px;
      margin-bottom: -15px;
      canvas {
        height: 90px !important;
      }
    }
  }
  .card-stats {
    width: 100%;
    display: inline-block;
    margin-top: 2px;
    margin-bottom: -6px;
    .card-stats-title {
      padding: 15px 25px;
      background-color: #fff;
      font-size: 13px;
      font-weight: 600;
      letter-spacing: 0.3px;
    }
    .card-stats-items {
      display: flex;
      height: 50px;
      align-items: center;
    }
    .card-stats-item {
      width: calc(100% / 3);
      text-align: center;
      padding: 5px 20px;
      .card-stats-item-label {
        font-size: 12px;
        letter-spacing: 0.5px;
        margin-top: 4px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      .card-stats-item-count {
        line-height: 1;
        margin-bottom: 8px;
        font-size: 20px;
        font-weight: 700;
      }
    }
  }
  &.card-large-icons {
    display: flex;
    flex-direction: row;
    .card-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      width: 150px;
      border-radius: 3px 0 0 3px;
      .ion,
      .fas,
      .far,
      .fab,
      .fal {
        font-size: 60px;
      }
    }
    .card-body {
      padding: 25px 30px;
      h4 {
        font-size: 18px;
      }
      p {
        opacity: 0.6;
        font-weight: 500;
      }
      a.card-cta {
        text-decoration: none;
        i {
          margin-left: 7px;
        }
      }
    }
  }
  &.bg-primary,
  &.bg-danger,
  &.bg-success,
  &.bg-info,
  &.bg-dark,
  &.bg-warning {
    color: #fff;
  }
  &.bg-primary .card-header,
  &.bg-danger .card-header,
  &.bg-success .card-header,
  &.bg-info .card-header,
  &.bg-dark .card-header,
  &.bg-warning .card-header {
    color: #fff;
    opacity: 0.9;
  }
  .card-type-3 {
    .card-circle {
      display: inline-flex;
      text-align: center;
      border-radius: 50%;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      height: 45px;
      width: 45px;
      box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
        0 7px 10px -5px rgba(76, 175, 80, 0.4);
      i {
        font-size: 15px;
      }
    }
  }
  .card-statistic-3 {
    position: relative;
    color: #fff;
    padding: 15px;
    border-radius: 3px;
    overflow: hidden;
    .card-icon-large {
      font-size: 110px;
      width: 110px;
      height: 50px;
      text-shadow: 3px 7px rgba(0, 0, 0, 0.3);
    }
    .card-icon {
      text-align: center;
      line-height: 50px;
      margin-left: 15px;
      color: #000;
      position: absolute;
      right: -5px;
      top: 20px;
      opacity: 0.1;
    }
  }
}

@include media-breakpoint-down(xs) {
  .card {
    &.card-large-icons {
      display: inline-block;
      .card-icon {
        width: 100%;
        height: 200px;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .card {
    .card-header {
      height: auto;
      flex-wrap: wrap;
      h4 {
        + .card-header-action,
        + .card-header-form {
          flex-grow: 0;
          width: 100%;
          margin-top: 10px;
        }
      }
    }
  }
}

@include media-breakpoint-only(md) {
  .card {
    .card-stats {
      .card-stats-items {
        height: 49px;
        .card-stats-item {
          padding: 5px 7px;
          .card-stats-item-count {
            font-size: 16px;
          }
        }
      }
    }
    &.card-sm-6 {
      .card-chart {
        canvas {
          height: 85px !important;
        }
      }
    }
    &.card-hero {
      .card-header {
        padding: 25px;
      }
    }
  }
}
